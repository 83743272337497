<template>
    <div class="preview-ppt-component">
        <!-- 
        <template v-if="preview">
            <div class="difference-tips">*预览格式可能和下载后的最终格式不同</div>
            <template v-for="(item,index) in preview">
                <div class="sheet-content" :key="index">
                    <div class="sheet-name">Sheet{{(item.sheetIdx*1)+1}}</div>
                    <template>
                        <template v-if="item.type==='image'">
                            <p class="image">
                                <img :src="item.url ? item.url : ('data:image/jpg;base64,' + item.encode)" />
                            </p>
                        </template>
                        <template v-else-if="item.type==='table'">
                            <table class="preview-table" :key="index">
                                <thead></thead>
                                <tbody>
                                    <tr v-for="row in item.rowCount+1" :key="row" :data-key="row">
                                        <td v-for="col in item.colCount+1" :key="col">
                                            <template v-if="item.table[row-1]">
                                                <template v-if="item.table[row-1][col-1]">
                                                    <p :style="item.table[row-1][col-1].style">
                                                        <template v-for="(sent,sIndex) in item.table[row-1][col-1].sentences">
                                                            <span :key="sIndex" 
                                                                :data-sentid="sent.sentId" 
                                                                :class="[sentId === sent.sentId?'active':'']" 
                                                                :style="{
                                                                    ...sent.style,
                                                                    fontSize:(sent.style.fontSize*1<14?14:sent.style.fontSize)+'px',
                                                                    lineHeight:(parseFloat(sent.style.fontSize*1<14?14:sent.style.fontSize)+lineHeightAddition)+'px',
                                                                    backgroundColor:(sent.status === 'confirm'?'#CDF7E6':'')
                                                                }" 
                                                                v-if="active === 'source'">{{sent.source}}</span>
                                                            <span :key="sIndex" 
                                                                :data-sentid="sent.sentId" 
                                                                :class="[sentId === sent.sentId?'active':'']" 
                                                                :style="{
                                                                    ...sent.style,
                                                                    fontSize:(sent.style.fontSize*1<14?14:sent.style.fontSize)+'px',
                                                                    lineHeight:(parseFloat(sent.style.fontSize*1<14?14:sent.style.fontSize)+lineHeightAddition)+'px',
                                                                    backgroundColor:(sent.status === 'confirm'?'#CDF7E6':'')
                                                                }" 
                                                                v-else>{{sent.target}}</span>
                                                        </template>
                                                    </p>
                                                </template>
                                                <template v-else></template>
                                            </template>
                                            <template v-else></template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </template>
                </div>
            </template>
            
        </template>
        -->
        <div class="none-list-block" style="color:#888;font-size:14px;">
            {{$t('workbench.component_PreviewPPT_tips_1')}}
        </div>
    </div>
</template>
<script>
export default {
    name:'PreviewExcel',
    props:{
        active:{
            type:String,
            default:'source'
        },
        preview:{
            type:Array,
            default:[]
        },
        sentId:{
            type:String,
            default:'',
        }

    },
    data(){
        return {
            lineHeightAddition:9,
        }
    },
    methods:{

    },
    mounted(){

    }
}
</script>
<style lang="scss" scoped>
.preview-ppt-component{
    padding:0 20px;
    min-height: 200px;
    .difference-tips{
        font-size: 12px;
        line-height: 17px;
        color: #999999;
        text-align: center;
        margin-bottom: 12px;
    }
    .sheet-content{
        margin: 15px 0;
        .sheet-name{
            margin:0 15px 15px;
            font-weight: 600;
            font-size: 17px;
            text-align: center;
        }
    }
    p{
        margin-bottom: 10px;
        letter-spacing: 1.2px; 
        font-size: 14px;
        line-height: 23px;
        > span{
            border-bottom: 3px solid transparent;
            box-sizing: border-box;
            &.active{
                border-bottom-color: #7DD6B3;
            }
        }
        &.image{
            text-align: center;
            > img{
                width: 90%;
            }
        }
    }
    .preview-table{
        width: 100%;
        margin-bottom: 15px;
        > thead{
            > tr > th{
                border: 1px solid #0000001a;
            }
        }
        > tbody{
            > tr > td{
                padding: 10px;
                border: 1px solid #0000001a;
                min-width: 120px;
                > p{
                    margin-bottom: 0;
                    font-size: 14px;
                    line-height: 23px;
                }
            }
        }
    }
}
</style>
