var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-word-component"},[(_vm.preview)?[_c('div',{staticClass:"difference-tips"},[_vm._v(_vm._s(_vm.$t('workbench.component_PreviewWord_tips_1')))]),_vm._l((_vm.preview),function(item,index){return [(item.type==='paragraph')?[(['all','source'].indexOf(_vm.active) > -1)?_c('p',{key:`paragraph-source-${_vm.tools.getRandomId(6)}-${index}`,style:(item.style),attrs:{"lang":_vm.doc.sourceLang}},[_vm._l((item.sentences),function(sent,sIndex){return [_c('span',{key:`sent-source-${_vm.tools.getRandomId(6)}-${sIndex}`,class:[_vm.sentId === sent.sentId?'active':''],style:({
                                ...sent.style,
                                fontSize:(sent.style&&(sent.style.fontSize*1<14?14:sent.style.fontSize))+'px',
                                lineHeight:(sent.style&&(parseFloat(sent.style.fontSize<14?14:sent.style.fontSize)+_vm.lineHeightAddition))+'px',
                                backgroundColor:(sent.status === 'confirm'?'#CDF7E6':'')
                            }),attrs:{"data-sentid":sent.sentId},domProps:{"innerHTML":_vm._s(sent.source)}})]})],2):_vm._e(),(['all','target'].indexOf(_vm.active) > -1)?_c('p',{key:`paragraph-target-${_vm.tools.getRandomId(6)}-${index}`,style:({...item.style, textAlign:(_vm.doc.targetLang==='ar' && item.style.textAlign==='left')?'inherit':item.style.textAlign}),attrs:{"lang":_vm.doc.targetLang}},[_vm._l((item.sentences),function(sent,sIndex){return [_c('span',{key:`sent-target-${_vm.tools.getRandomId(6)}-${sIndex}`,class:[_vm.sentId === sent.sentId?'active':''],style:({
                                ...sent.style,
                                fontSize: (sent.style && (sent.style.fontSize * 1 < 14 ? 14 : sent.style.fontSize)) + 'px',
                                lineHeight: (sent.style && (parseFloat(sent.style.fontSize * 1 < 14 ? 14 : sent.style.fontSize) + _vm.lineHeightAddition)) + 'px',
                                backgroundColor: (sent.status === 'confirm' ? '#CDF7E6' : '')
                            }),attrs:{"data-sentid":sent.sentId},domProps:{"innerHTML":_vm._s(sent.target)}})]})],2):_vm._e()]:(item.type==='image')?[_c('p',{key:`image-${_vm.tools.getRandomId(6)}-${index}`,staticClass:"image"},[_c('img',{style:(`width:${item.width}px;height:${item.height}px;`),attrs:{"src":item.url ? item.url : ('data:image/jpg;base64,' + item.encode)}})])]:(item.type==='table')?[_c('nested-table',{key:`table-${_vm.tools.getRandomId(6)}-${index}`,attrs:{"data":item,"active":_vm.active,"sentId":_vm.sentId,"doc":_vm.doc}})]:_vm._e()]})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }