<template>
    <div class="comment-component">
         <div class="comment-panel">
            <div class="panel-title">
                <div class="title"><svg-icon name="ib-comment-o"></svg-icon> {{$t('workbench.component_CommentRight_title')}}</div>
                <div class="close" @click="closeQaPanel">
                    <el-tooltip :content="$t('workbench.component_CommentRight_tips_1')" placement="top">
                        <svg-icon name="ib-close"></svg-icon>
                    </el-tooltip>
                </div>
            </div>
            <div class="result-content">
                <div class="result-list">
                    <template v-if="list.length > 0">
                        <div class="item" v-for="(item, i) in list" :key="i">
                            <div class="left-content">
                                <div class="profile">{{item.author&&item.author.charAt(0).toUpperCase()}}</div>
                            </div>
                            <div class="right-content">
                                <div class="flex flex-justify-between" style="margin-bottom:10px;">
                                    <div class="fonts-12" style="line-height:20px;">
                                        <span class="username">{{item.author}}</span>
                                        <span class="datetime">{{item.createTime}}</span>
                                    </div>
                                    <div class="actions" @click="deleteBtnEvent(item)" v-if="item.allowDelete">
                                        <svg-icon name="ib-trash-o"></svg-icon>
                                    </div>
                                </div>
                                <div class="comment-content" v-html="item.contentHtml"></div>
                            </div>
                        </div>
                    </template>
                    <div class="none-block" v-else>{{$t('workbench.component_CommentRight_empty_message')}}</div>
                    <div class="add-comment">
                        <el-input type="textarea" resize="none" :autosize="{minRows: 2, maxRows: 6 }" 
                            :placeholder="$t('workbench.component_CommentRight_input_placeholder')" 
                            maxlength="200"
                            show-word-limit
                            v-model="newCommentValue" 
                            @keydown.native="handleNewMessage($event)"
                        ></el-input>
                        <div class="send-btn">
                            <el-button type="" plain round size="mini" @click="newCommentValue = ''">{{$t('workbench.component_CommentRight_btn_1')}}</el-button>
                            <el-tooltip :content="$t('workbench.component_CommentRight_tips_2')" placement="top">
                                <el-button type="primary" round size="mini" @click="sendNewMessageEvent">{{$t('workbench.component_CommentRight_btn_2')}}</el-button>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <alert-dialog ref="alertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="submitDeleteBtnEvent"></alert-dialog>
    </div>
</template>
<script>
import $ from 'jquery';
import { docApi } from '@/utils/api';
import AlertDialog from '@/components/AlertDialog';
export default {
    props: {
        doc: {
            type: Object,
            default: {},
        },
        sentPermission: {
            type: Boolean,
            default: false,
        },
    },
    components:{AlertDialog},
    data(){
        return {
            list: [],
            newCommentValue: '',
            editCommentValue: '',
            selectedItem: null,
            alertInfo:{
                message: this.$t('workbench.component_CommentRight_data_alertInfo_message'),
                type:'confirm', //alert ,confirm
                buttonName: this.$t('workbench.component_CommentRight_data_alertInfo_buttonName'),
                buttonType:'danger',
            },
            sentenceId: '',
        }
    },
    methods: {
        initList(param){
            
            this.sentenceId = $('.editer-content .item.active').data('sentid');
            if(param) {
                this.sentenceId = param.sentId;
            }
            let docId = this.doc.docId;
            let url = `${docApi.queryCommentList}?documentId=${docId}&sentenceId=${this.sentenceId || ''}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.list = res.data.map(item=>{
                        return {
                            ...item,
                            contentHtml: '<p>'+item.content.split(/\n/g).join('</p><p>')+'</p>',
                        }
                    });
                    this.$emit('action','updateSentCommentCount',{id: this.sentenceId, count: this.list.length});
                }
            });
        },
        closeQaPanel(){
            this.$emit('close');
        },
        handleNewMessage(event){
            if (event.keyCode === 13) {
                if (!(event.metaKey||event.shiftKey)) {
                    event.preventDefault();
                    this.sendNewMessageEvent();
                } else {
                    // this.newCommentValue = this.newCommentValue + '\n';
                }
            }
        },
        sendNewMessageEvent(){
            if(!this.sentenceId){
                this.$message.error(this.$t('workbench.component_CommentRight_method_sendNewMessageEvent_message_1'));
                return;
            }
            if(!this.newCommentValue){
                this.$message.error(this.$t('workbench.component_CommentRight_method_sendNewMessageEvent_message_2'));
                return;
            }
            let docId = this.doc.docId;
            let url = docApi.createComment;
            let postData = {
                documentId: docId,
                sentenceId: this.sentenceId,
                content: this.newCommentValue,
            };
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.newCommentValue = '';
                    this.initList(this.sentenceId ? {sentId: this.sentenceId} : '');
                }
            })
        },
        deleteBtnEvent(item){
            this.$refs.alertDialogDom.setShow(true);
            this.selectedItem = item;
        },
        submitDeleteBtnEvent(){
            let url = `${docApi.deleteComment}?commentId=${this.selectedItem.commentId}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.$message.success(this.$t('workbench.component_CommentRight_method_submitDeleteBtnEvent_message_1'));
                    this.initList(this.sentenceId ? {sentId: this.sentenceId} : '');
                }
            })

        },
    },
    mounted() {
        // this.initList();
    }
}
</script>
<style lang="scss" scoped>
.comment-component{
    height: 100%;
    width: 380px;
    overflow-y: hidden;
    .comment-panel{
        display: flex;
        flex-direction: column;
        height: 100%;
        .panel-title{
            margin: 0 20px;
        }
        .form-content{
            margin: 20px 20px 0 20px;
            border-bottom:1px solid #F1F1F1;
        }
        .result-content{
            flex: 1;
            position: relative;
            .result-list{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                overflow: auto;
                padding: 10px 20px;
                > .item{
                    border-radius: 8px; 
                    border-bottom: 1px solid #F1F1F1;
                    box-sizing: border-box;
                    margin-bottom: 8px;
                    padding: 15px 0px;
                    display: flex;
                    .left-content{
                        .profile{
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            color: #ffffff;
                            font-size: 13px;
                            font-weight: 600;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            background-color:#7DD6B3;
                        }
                    }
                    .right-content{
                        flex: 1;
                        margin-left: 10px;
                        .username{
                            font-weight:600;
                        }
                        .datetime{
                            margin-left:10px;
                            color: #808080;
                        }
                        .actions{
                            font-size: 16px;
                            color: #000000;
                            svg{
                                cursor: pointer;
                                &:last-child{
                                    margin-left: 10px;
                                }
                            }
                        }
                        .comment-content{
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }
            .none-block{
                margin-top: 20px;
                text-align: center;
            }
            .add-comment{
                padding: 10px 0;
                ::v-deep{
                    .el-input__inner{
                        padding-right: 40px;
                    }
                }
                .send-btn{
                    margin-top: 10px;
                    text-align: right;
                }
            }
        }
    }
    
}
</style>
