import { render, staticRenderFns } from "./PreviewPPT.vue?vue&type=template&id=783f3ac2&scoped=true"
import script from "./PreviewPPT.vue?vue&type=script&lang=js"
export * from "./PreviewPPT.vue?vue&type=script&lang=js"
import style0 from "./PreviewPPT.vue?vue&type=style&index=0&id=783f3ac2&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "783f3ac2",
  null
  
)

export default component.exports