<template>
    <div class="preview-excel-component">
        <template v-if="preview">
            <div class="difference-tips">{{$t('workbench.component_PreviewExcel_tips_1')}}</div>
            <template v-for="(item,index) in preview">
                <div class="sheet-content" :key="`sheet-${tools.getRandomId(6)}-${index}`">
                    <div class="sheet-name">Sheet{{(item.sheetIdx*1)+1}}</div>
                    <template>
                        <template v-if="item.type==='image'">
                            <p class="image" :key="`image-${tools.getRandomId(6)}-${index}`">
                                <img :src="item.url ? item.url : ('data:image/jpg;base64,' + item.encode)" />
                            </p>
                        </template>
                        <template v-else-if="item.type==='table'">
                            <table class="preview-table" :key="`table-${tools.getRandomId(6)}-${index}`">
                                <thead></thead>
                                <tbody>
                                    <tr v-for="row in item.maxRowIdx+1" :key="`row-${tools.getRandomId(6)}-${row}`" :data-key="row">
                                        <td v-for="col in item.maxColIdx+1" :key="`col-${tools.getRandomId(6)}-${col}`">
                                            <template v-if="item.table[row-1]">
                                                <template v-if="item.table[row-1][col-1]">
                                                    <template v-for="(paragraph,pIndex) in item.table[row-1][col-1]">
                                                        <p :lang="doc.sourceLang" 
                                                            :style="paragraph.style" 
                                                            :key="`paragraph-source-${tools.getRandomId(6)}-${pIndex}`" 
                                                            v-if="['all','source'].indexOf(active) > -1">
                                                            <template v-for="(sent,sIndex) in paragraph.sentences">
                                                                <span :key="`sent-source-${tools.getRandomId(6)}-${sIndex}`" 
                                                                    :data-sentid="sent.sentId" 
                                                                    :class="[sentId === sent.sentId?'active':'']" 
                                                                    :style="{
                                                                        ...sent.style,
                                                                        fontSize:(sent.style.fontSize*1<14?14:sent.style.fontSize)+'px',
                                                                        lineHeight:(parseFloat(sent.style.fontSize*1<14?14:sent.style.fontSize)+lineHeightAddition)+'px',
                                                                        backgroundColor:(sent.status === 'confirm'?'#CDF7E6':'')
                                                                    }" 
                                                                    v-html="sent.source"
                                                                ></span>
                                                            </template>
                                                        </p>
                                                        <p :lang="doc.targetLang" 
                                                            :style="{...paragraph.style, textAlign:(doc.targetLang==='ar' && paragraph.style.textAlign==='left')?'inherit':paragraph.style.textAlign}" 
                                                            :key="`paragraph-target-${tools.getRandomId(6)}-${pIndex}`" 
                                                            v-if="['all','target'].indexOf(active) > -1">
                                                            <template v-for="(sent,sIndex) in paragraph.sentences">
                                                                <span :key="`sent-target-${tools.getRandomId(6)}-${sIndex}`" 
                                                                    :data-sentid="sent.sentId" 
                                                                    :class="[sentId === sent.sentId?'active':'']" 
                                                                    :style="{
                                                                        ...sent.style,
                                                                        fontSize:(sent.style.fontSize*1<14?14:sent.style.fontSize)+'px',
                                                                        lineHeight:(parseFloat(sent.style.fontSize*1<14?14:sent.style.fontSize)+lineHeightAddition)+'px',
                                                                        backgroundColor:(sent.status === 'confirm'?'#CDF7E6':'')
                                                                    }" 
                                                                    v-html="sent.target"
                                                                ></span>
                                                            </template>
                                                        </p>
                                                    </template>
                                                </template>
                                                <template v-else></template>
                                            </template>
                                            <template v-else></template>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </template>
                    </template>
                </div>
            </template>
            
        </template>
    </div>
</template>
<script>
import tools from '@/utils/tools';
export default {
    name:'PreviewExcel',
    props:{
        doc: {
            type: Object,
            default: {}
        },
        active:{
            type:String,
            default:'source'
        },
        preview:{
            type:Array,
            default:[]
        },
        sentId:{
            type:String,
            default:'',
        }

    },
    data(){
        return {
            lineHeightAddition:9,
            tools,
        }
    },
    methods:{

    },
    mounted(){

    }
}
</script>
<style lang="scss" scoped>
.preview-excel-component{
    padding:0 20px;
    min-height: 200px;
    .difference-tips{
        font-size: 12px;
        line-height: 17px;
        color: #999999;
        text-align: center;
        margin-bottom: 12px;
    }
    .sheet-content{
        margin: 15px 0;
        .sheet-name{
            margin:0 15px 15px;
            font-weight: 600;
            font-size: 17px;
            text-align: center;
        }
    }
    p{
        margin-bottom: 10px;
        letter-spacing: 1.2px; 
        font-size: 14px;
        line-height: 23px;
        > span{
            position: relative;
            border-bottom: 3px solid transparent;
            box-sizing: border-box;
            &:hover {
                // background-color: #7dd6b221;
            }
            &.active{
                border-bottom-color: #7DD6B3;
            }
        }
        &.image{
            text-align: center;
            > img{
                width: 90%;
            }
        }
    }
    .preview-table{
        width: 100%;
        margin-bottom: 15px;
        > thead{
            > tr > th{
                border: 1px solid #0000001a;
            }
        }
        > tbody{
            > tr > td{
                padding: 10px;
                border: 1px solid #0000001a;
                min-width: 120px;
                > p{
                    margin-bottom: 0;
                    font-size: 14px;
                    line-height: 23px;
                }
            }
        }
    }
}
</style>
