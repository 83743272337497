<template>
    <div class="find-mt-right-components">
        <div class="find-mt-panel">
            <div class="form-content">
                <div class="back-btn" @click="closeQaPanel">
                    <i class="el-icon-back"></i>
                </div>
                <el-input ref="kwInput" v-model="kw" @input="inputSearchEvent" maxlength="100" autofocus clearable :placeholder="$t('workbench.component_FindMTRight_search_input_placeholder')" suffix-icon="el-icon-search"></el-input>
            </div>
            <div class="tabs-content">
                <div :class="['tab-item', activeTab === 'memory'? 'active' : '']" @click="activeTab = 'memory'">{{$t('workbench.component_FindMTRight_tabs_item_1')}}({{this.list.length}})</div>
                <div :class="['tab-item', activeTab === 'term'? 'active' : '']" @click="activeTab = 'term'">{{$t('workbench.component_FindMTRight_tabs_item_2')}}({{this.termList.length}})</div>
            </div>
            <div class="result-content">
                <template v-if="activeTab === 'memory'">
                    <div class="result-list" v-if="list.length > 0">
                        <div class="item" v-for="(item, i) in list" :key="i">
                            <div class="info-content">
                                <div class="source" :lang="doc.sourceLang" v-html="item.sourceHtml"></div>
                                <div class="target" :lang="doc.targetLang" v-html="item.targetHtml"></div>
                            </div>
                            <div class="icon-btn" @click="copyEvent(item.targetText)">
                                <el-tooltip :content="$t('workbench.component_FindMTRight_tips_1')" placement="top">
                                    <svg-icon name="ib-copy"></svg-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="none-block" v-else>{{$t('workbench.component_FindMTRight_empty_message_1')}}</div>
                </template>
                <template v-if="activeTab === 'term'">
                    <div class="result-list" v-if="termList.length > 0">
                        <div class="term-item" v-for="(item, i) in termList" :key="i">
                            <div class="info-content">
                                <div class="source" :lang="doc.sourceLang" v-html="item.sourceText"></div>
                                <div class="target" :lang="doc.targetLang" v-html="item.targetText"></div>
                            </div>
                            <div class="icon-btn" @click="copyEvent(item.targetText)">
                                <el-tooltip :content="$t('workbench.component_FindMTRight_tips_1')" placement="top">
                                    <svg-icon name="ib-copy"></svg-icon>
                                </el-tooltip>
                            </div>
                        </div>
                    </div>
                    <div class="none-block" v-else>{{$t('workbench.component_FindMTRight_empty_message_1')}}</div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import qs from 'querystring';
import { docApi } from '@/utils/api';
export default {
    props:{
        doc:{
            type: Object,
            default: {}
        }
    },
    data(){
        return {
            kw: '',
            list: [],
            termList: [],
            searchTimer: null,
            activeTab: 'memory', // term
        }
    },
    methods:{
        closeQaPanel(){
            this.$emit('close');
        },
        inputSearchEvent(){
            clearTimeout(this.searchTimer);
            if(this.kw.length>0){
                this.searchTimer = setTimeout(()=>{
                    this.queryResult();
                },500);
            }else{
                this.list = [];
            }
        },
        queryResult(){
            let valid = false;
           /*  if(this.kw.match(/^[A-Z][0-9]+$/)){
                if(this.kw.length >= 2){
                    valid = true;
                }else{
                    valid = false;
                }
            }else{
                if(this.kw.length >= 1){
                    valid = true;
                }else{
                    valid = false;
                }
            } */
            if(this.kw.length > 0){
                valid = true;
            }
            if(!valid){
                this.list = [];
                this.termList = [];
                return;
            }
            let params = {
                projectId: this.doc.projectId,
                sourceLang: this.doc.sourceLang,
                targetLang: this.doc.targetLang,
                segment: encodeURI(this.kw)
            };
            let url = `${docApi.queryMemorySegments}?${qs.stringify(params)}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    let reg = new RegExp(this.kw, "gi");
                    this.list = res.data.map(item=>{
                        return {
                            ...item,
                            sourceHtml: item.sourceText.replaceAll(reg, char=>{return `<span class="flag">${char}</span>`}),
                            targetHtml: item.targetText.replaceAll(reg, char=>{return `<span class="flag">${char}</span>`}),
                        }
                    });
                }
            });
            let tUrl = `${docApi.queryTermSegments}?${qs.stringify(params)}`;
            this.$ajax.get(tUrl).then(res=>{
                if(res.status === 200){
                    this.termList = res.data;
                }
            });

        },
        setKeyword(kw){
            this.kw = kw;
        },
        copyEvent(text){
            let self = this;
            this.$copyText(text).then(function (e) {
                self.$message.success(this.$t('workbench.component_FindMTRight_method_copyEvent_message_1'));
            }, function (e) {
                self.$message.error(this.$t('workbench.component_FindMTRight_method_copyEvent_message_2'));
            })
        },
    },
    mounted(){
        this.$refs.kwInput.focus();
    }
}
</script>
<style lang="scss" scoped>
.find-mt-right-components{
    height: 100%;
    width: 380px;
    
    overflow-y: hidden;
    .find-mt-panel{
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        height: 100%;
        .panel-title{
            margin: 0 20px;
        }
        .form-content{
            height: 40px;
            margin-bottom: 15px;
            border:1px solid #e5e5e5;
            box-sizing: border-box;
            border-radius: 6px;
            display: flex;
            align-items: center;
            .back-btn{
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 4px;
                background-color: #F2F2F2;
                border-radius: 6px;
                cursor: pointer;
                i {
                    font-size: 20px;
                    color: #000;
                }
            }
            ::v-deep .el-input__inner{
                border-color: transparent;
                background-color: transparent;
            }
        }
        .tabs-content{
            display: flex;
            margin-bottom: 10px;
            .tab-item{
                color: #666666;
                font-size: 12px;
                margin-right: 30px;
                cursor: pointer;
                &.active{
                    color: #000;
                    font-weight: 600;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
        .result-content{
            flex: 1;
            position: relative;
            .result-list{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                overflow: auto;
                padding: 10px 20px;
                > .item{
                    // height: 40px;
                    background-color:#FAFAFA;
                    border-radius: 8px; 
                    border: 1px solid #ffffff;
                    box-sizing: border-box;
                    margin-bottom: 8px;
                    padding: 15px 20px;
                    display: flex;
                    .info-content{
                        flex: 1;
                        margin-right: 10px;
                        font-size: 14px;
                        line-height: 20px;
                        > .source{
                            margin-bottom: 10px;
                        }
                        ::v-deep .flag{
                            background-color: #fff370;
                        }
                    }
                    .icon-btn{
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
                > .term-item{
                    background-color: #FAFAFA;
                    border-radius: 36px;
                    padding: 8px 19px;
                    margin-bottom: 10px;
                    margin-right: 10px;
                    display: flex;
                    .info-content{
                        flex: 1;
                        font-size: 14px;
                        line-height: 20px;
                        display: flex;
                        flex-wrap: wrap;
                        > div{
                            &.source{
                                margin-right: 20px;
                            }
                            &.target{
                                color: #00B670;
                                cursor: pointer;
                            }
                        }
                    }
                    .icon-btn{
                        cursor: pointer;
                    }
                }
            }
            .none-block{
                margin-top: 20px;
                text-align: center;
            }
        }
    }
    
}
</style>
