<template>
    <table class="preview-table">
        <tbody>
            <tr v-for="row in data.maxRowIdx+1" :key="`row-${tools.getRandomId(6)}-${row}`" :data-key="row">
                <td v-for="col in data.maxColIdx+1" :key="`col-${tools.getRandomId(6)}-${col}`">
                    <template v-if="data.table[row-1]">
                        <template v-if="data.table[row-1][col-1]">
                            <template v-for="(paragraph,pIndex) in data.table[row-1][col-1]">
                                <template v-if="paragraph.type === 'paragraph'">
                                    <!-- <span>{{data.table[row-1][col-1]}}</span> -->
                                    <p :lang="doc.sourceLang" :style="paragraph.style||{}" :key="`paragraph-source-${tools.getRandomId(6)}-${pIndex}`" v-if="['all','source'].indexOf(active) > -1">
                                        <template v-for="(sent,sIndex) in paragraph.sentences">
                                            <span :key="`sent-source-${tools.getRandomId(6)}-${sIndex}`" 
                                                :data-sentid="sent.sentId||''" 
                                                :class="[sentId === sent.sentId?'active':'']" 
                                                :style="sent.style?{
                                                    ...(sent.style||{}),
                                                    fontSize:(sent.style.fontSize*1<14?14:sent.style.fontSize)+'px',
                                                    lineHeight:(parseFloat(sent.style.fontSize*1<14?14:sent.style.fontSize)+lineHeightAddition)+'px',
                                                    backgroundColor:(sent.status === 'confirm'?'#CDF7E6':'')
                                                }:{}" 
                                                v-html="sent.source"
                                            ></span>
                                        </template>
                                    </p>
                                    <p :lang="doc.targetLang" 
                                        :style="paragraph.style?{...paragraph.style, textAlign:(doc.targetLang==='ar' && paragraph.style.textAlign==='left')?'inherit':paragraph.style.textAlign}:{}" 
                                        :key="`paragraph-target-${tools.getRandomId(6)}-${pIndex}`" 
                                        v-if="['all','target'].indexOf(active) > -1">
                                        <template v-for="(sent,sIndex) in paragraph.sentences">
                                            <span :key="`sent-target-${tools.getRandomId(6)}-${sIndex}`" 
                                                :data-sentid="sent.sentId||''" 
                                                :class="[sentId === sent.sentId?'active':'']" 
                                                :style="sent.style?{
                                                    ...(sent.style||{}),
                                                    fontSize:(sent.style.fontSize*1<14?14:sent.style.fontSize)+'px',
                                                    lineHeight:(parseFloat(sent.style.fontSize*1<14?14:sent.style.fontSize)+lineHeightAddition)+'px',
                                                    backgroundColor:(sent.status === 'confirm'?'#CDF7E6':'')
                                                }:{}" 
                                                v-html="sent.target"
                                            ></span>
                                        </template>
                                    </p>
                                </template>
                                <template v-else-if="paragraph.type === 'image'">
                                    <p :key="`image-${tools.getRandomId(6)}-${pIndex}`" class="image">
                                        <img :src="item.url ? item.url : ('data:image/jpg;base64,' + item.encode)" alt="image"/>
                                    </p>
                                </template>
                                <template v-else-if="paragraph.type === 'table'">
                                    <NestedTable :data="paragraph" :active="active" :doc="doc" :sentId="sentId" :key="`child-table-${tools.getRandomId(6)}-${pIndex}`"></NestedTable>
                                </template>
                            </template>
                        </template>
                    </template>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
import tools from '@/utils/tools';
export default {
    name: 'NestedTable',
    props:{
        data:{
            type: Object,
            default: {},
        },
        active:{
            type: String,
            default: '',
        },
        doc:{
            type: Object,
            default: {},
        },
        sentId:{
            type:String,
            default:'',
        }
    },
    data(){
        return{
            lineHeightAddition:12,
            tools,
        }
    },
    mounted(){
        console.log('data:::',this.data)
    }
}
</script>
<style lang="sass" scoped>

</style>
