<template>
    <div class="basic-editer-vertical-component">
        <div class="editer-main">
            <template v-for="(item,index) in doc.sentences">
                <div :key="index" 
                    :data-docIndex="index" 
                    :data-lineNumber="(pagination.pageSize * (pagination.pageNumber - 1) + 1 + index)" 
                    :class="['item',item.editorStatus === 'confirm'?'saved':'',activeItemIndex === index?'active':'']" 
                    @mouseover="itemMouseoverEvent" @mouseleave="itemMouseleaveEvent">
                    <div class="original-doc">
                        <div class="tools">
                            <div class="line-number">{{(pagination.pageSize * (pagination.pageNumber - 1) + 1 + index)}}</div>
                            <div class="merge-icon"></div>
                        </div>
                        <div class="doc" contenteditable="true" tabindex="1" :data-doc="item.sourceText"
                            @keydown="e=>{e.preventDefault();e.returnValue=false;return false;}"
                            @input="originalDocInputEvent" v-html="item.sourceText||''"></div>
                        <div class="tools">
                            <div class="split-icon"></div>
                        </div>
                    </div>
                    <div class="target-doc">
                        <div class="doc-input">
                            <div class="doc" contenteditable="true" tabindex="0" :data-id="item.sentId" :placeholder="item.translationText"
                                @keydown.enter="e=>linePressEnterDownEvent(e,index,item)" 
                                @keydown.right="e=>linePressRightEvent(e,index,item)"
                                @keydown.tab="e=>linePressTabEvent(e,index,item)"
                                @keyup.enter="e=>linePressEnterUpEvent(e,index,item)"
                                @focus="e=>lineFocusEvent(e,index,item)"
                                @blur="e=>lineBlurEvent(e,index,item)"
                                @input="e=>lineInputEvent(e,index,item)" 
                                v-html="item.targetText||''">
                            </div>
                            <div class="tools"> 
                                <div class="save-btn">
                                    <div class="btn" @click="autoSaveSentences(index,'confirm',false)">确认</div>
                                </div>
                                <div class="doc-word-num">{{item.customText&&item.customText.length||0}}</div>
                            </div>
                        </div>
                        <template>
                            <div class="doc-translate" @click="e => replaceCustomTextEvent(e,index,item)" v-if="item.editorStatus !=='confirm'">
                                <div class="translate-text" title="点击应用" v-html="item.translationText||''"></div>
                                <div class="translate-engine">
                                    <el-tooltip :content="engineDist[doc.engine].name+'翻译'">
                                        <svg-icon :name="'ie-'+engineDist[doc.engine].icon" style="width: 15px;height: 15px;"></svg-icon>
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            
        </div>
        <div class="pagination-content" v-if="doc.sentences.length > 0 && pagination.total > pagination.pageSize">
            <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page.sync="pagination.pageNumber"
                :page-size="pagination.pageSize"
                layout="total, prev, pager, next"
                :total="pagination.total">
            </el-pagination>
        </div>
        <div class="target-autocomplete-input">
            <template v-for="(item,index) in autocompleteList">
                <div :class="['item',index === targetAutocompleteActiveIndex?'active':'']" :key="index" :data-autoindex="index">{{item}}</div>
            </template>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import $ from 'jquery';
import config from '@/utils/config';
import tools from '@/utils/tools';
import { docApi } from '@/utils/api';
export default {
    name:'BasicEditer',
    props:{
        doc:{
            type:Object,
            default:{}
        },
        pagination:{
            type:Object,
            default:{
                pageSize:200,
                pageNumber:1,
                total:0
            }
        }
    },
    data(){
        return{
            engineDist:tools.getEngineDist(config.DOMAIN_AND_ENGINE_LIST),
            autocompleteList:[],
            isSelectedTargetAutocomplete:false,
            targetAutocompleteActiveIndex:0,
            autoSaveTimer:null,
            getMTResultIndex:-1,
            activeItemIndex:0,
        }
    },
    computed: {
        ...mapState({
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
    },
    methods:{
        setActiveItemIndex(num){
            this.activeItemIndex = num;
        },
        handleCurrentChange(num){
            this.$emit('pageNumChange',num);
        },
        originalDocInputEvent(e){
            $(e.target).html($(e.target).data('doc'));
        },
        itemMouseoverEvent(e){
            let dom=$(e.target);
            if($(e.target).parents('.item').length>0){
                dom=$(e.target).parents('.item');
            }
            // dom.find('.line-number').css({'display': 'flex'});
            dom.find('.merge-icon').css({'display': 'block'});
            dom.find('.split-icon').css({'display': 'block'});
            // dom.next().find('.line-number').css({'display': 'flex'});
        },
        itemMouseleaveEvent(e){
            let dom=$(e.target);
            if($(e.target).parents('.item').length>0){
                dom=$(e.target).parents('.item');
            }
            // dom.find('.line-number').css({'display': 'none'});
            dom.find('.merge-icon').css({'display': 'none'});
            dom.find('.split-icon').css({'display': 'none'});
            // dom.next().find('.line-number').css({'display': 'none'});
        },
        linePressEnterDownEvent(e,index,item){
            let value = $(e.target).html();
            this.isSelectedTargetAutocomplete = $('.target-autocomplete-input').css('display') === 'block';
            this.autoSaveSentences(index,'confirm',false);
            event.preventDefault();
            return false;
        },
        linePressEnterUpEvent(e,index,item){
            if(this.isSelectedTargetAutocomplete){
            }else{
                if(index === this.doc.sentences.length-1){
                    // this.$message.warning('已经是最后一条了')
                }else{
                    $($('.target-doc .doc')[index+1]).focus();
                    tools.setCursorAtTheEnd($('.target-doc .doc')[index+1]);
                    this.activeItemIndex = index+1;
                }
            }
            
        },
        linePressTabEvent(e,index,item){
            this.replaceCustomTextEvent(e,index,item);

            e.preventDefault();
            return false;
        },
        lineFocusEvent(e,index,item){
            this.activeItemIndex = index;
            if(!item.participles || item.participles.length === 0){
                this.queryTranslationTextParticiple(e,index,item);
            }
            if(!$(e.target).parents('.item').hasClass('active')){
                this.$emit('getRelationInfo',item);
            }
        },
        lineBlurEvent(e,index,item){
            //输入框失去焦点时获取光标位置
            let position =tools.getCursortPosition(e.target);
            // console.log("position:",position);
            this.$emit('setCursortPosition',position);
        },
        lineInputEvent(e,index,item){
            let self = this;
            // 智能提示部分逻辑
            // this.initTargetAutocomplete(e,index,item);
            this.initTargetTranslationText(e,index,item);
            this.queryTranslationTextParticiple(e,index,item);
            // 自动保存
            if(this.autoSaveTimer){
                clearTimeout(this.autoSaveTimer);
            }
            this.autoSaveTimer = setTimeout(()=>{
                self.autoSaveSentences(index,'non-confirm',false)
            },1000);
        },
        linePressRightEvent(e,index,item){
            let value = $(e.target).text().replace(/\s+/g,' ');
            // let range = window.getSelection();
            let offset = tools.getCursortPosition(e.target)
            if (offset === value.length){
                let tmpIndex = 0;
                let rst = value;
                if(value){
                    let tmpStr='';
                    for(let i = 0; i<item.participles.length; i++){
                        let p = item.participles[i];
                        tmpStr = tmpStr + p;
                        if(tmpStr.toLowerCase().replace(/\s+/g,' ') === value.toLowerCase().replace(/\s+/g,' ')){
                            tmpIndex = i + 1;
                            break;
                        }
                    }
                }
                if(item.participles.length > 0 && tmpIndex <= item.participles.length - 1){
                        rst = rst + item.participles[tmpIndex];
                }
                if (['zh','ja'].indexOf(this.doc.targetLang) < 0) {
                    rst = rst + ' ';
                }

                // console.log(rst,item,tmpIndex,tmpStr)
                this.doc.sentences[index].customText = rst;
                let node=$(e.target).parents('.target-doc').find('.doc');
                node.html(rst);
                this.initTargetTranslationText(e,index,item);
                tools.setCursorAtTheEnd(node[0]);
                this.queryTranslationTextParticiple(e,index,item)
                this.autoSaveSentences(index,'non-confirm',false);
            }
        },
        autoSaveSentences(index,status,isFocus){
            this.$emit('saveSentences',index,status,isFocus);
        },
        // 分词算法，中文日文掉分词接口，其他语言按空格分词
        queryTranslationTextParticiple(e,index,item){
            let value =item.translationText ? item.translationText.replace(/\s+/g,' ') : '';
            let lowerTranslationText = value.toLowerCase();
            let lowerText = $(e.target).text().toLowerCase().replace(/\s+/g,' ');
            
            if (lowerText.length > 0) {
                if (lowerTranslationText.indexOf(lowerText) !== -1) {
                    value = item.translationText ? item.translationText.substring(lowerText.length) : '';
                }else{
                    value = '';
                }
            }
            if(value){
                if(['zh','ja'].indexOf(this.doc.targetLang) > -1){ //中日掉接口分词 
                    let url = docApi.querySentencesParticiple;
                    let postData = {
                        text: value,
                        lang: this.doc.targetLang
                    }
                    this.$ajax.post(url,postData).then(res=>{
                        if(res.status === 200){
                            let rst = res.data;
                            this.doc.sentences[index]['participles'] = rst;
                            console.log('分词结果：：', rst);
                        }
                    });
                }else{ //其他语种空格分词
                    let rst = value.split(' ').filter(item => item !== '' && item);
                    this.doc.sentences[index]['participles'] = rst;
                    console.log('分词结果：：', rst);
                }
            }else{
                this.doc.sentences[index]['participles'] = [];
                console.log('分词结果：：', []);
            }
        },
        getMTResult(e,index,item){
            let node = $('.editer-main .item.active').find('.target-doc .doc');
            let _index = $('.editer-main .item.active').data('docindex');
            let _item = this.doc.sentences[index];
            if (e) {
                node = $(e.target).parents('.item').find('.target-doc .doc');
                _index = index;
                _item = item;
            }
            if(_item.translationText){
                node.html(_item.translationText);
                this.autoSaveSentences(index,'non-confirm',false);
            }else{
                let url = docApi.translationRandomDoc;
                let postData={
                    domain:this.doc.domain,
                    engine:this.doc.engine,
                    sourceLang:this.doc.sourceLang,
                    sourceText:_item.sourceText,
                    targetLang:this.doc.targetLang
                };
                this.getMTResultIndex = _index;
                this.$ajax.post(url,postData).then(res=>{
                    if(res.status === 200){
                        node.html(res.data||'');
                        this.autoSaveSentences(index,'non-confirm',false);
                    }
                }).finally(()=>{
                    this.getMTResultIndex = -1;
                })
            }
        },
        initTargetAutocomplete(e,index,item){
            let value = $(e.target).text();
            this.doc.sentences[index].customText=value; 
            $(e.target).find('.position-flag').remove();
            $(e.target).append('<span class="position-flag"></span>');
            let top = $(e.target).find('.position-flag').offset().top+20;
            let left = $(e.target).find('.position-flag').offset().left;
            let rst = '';
            let _value = value.toLowerCase().replace(/\s+/g,' ');
            let _translationText = item.translationText ? item.translationText.toLowerCase().replace(/\s+/g,' '):'';
            if(_translationText.indexOf(_value) === 0){
                rst = item.translationText.substring(value.length,item.translationText.length);
            }
            if(rst === item.translationText){
                rst='';
            }
            if(rst){
                this.autocompleteList = [rst];
                $('.target-autocomplete-input').css({
                    top:top+'px',
                    left:left+'px',
                    display:'block'
                });
            }else{
                this.autocompleteList = [];
                $('.target-autocomplete-input').css({
                    top:top+'px',
                    left:left+'px',
                    display:'none'
                });
            }
        },
        initTargetTranslationText(e,index,item){
            let value = $(e.target).text();
            this.doc.sentences[index].customText=value; 
            let rst = item.translationText;
            let _value = value.toLowerCase().replace(/\s+/g,' ');
            let _translationText = item.translationText ? item.translationText.toLowerCase().replace(/\s+/g,' '):'';
            if(_translationText.indexOf(_value) === 0){
                let afterStr = item.translationText.substring(0,value.length);
                let beforeStr = item.translationText.substring(value.length,item.translationText.length);
                rst = '<span style="color: #6666664D;">'+afterStr+'</span>'+beforeStr;
            }else{
                rst = '<span style="color: #6666664D;">'+rst+'</span>';
            }
            $(e.target).parents('.target-doc').find('.doc-translate .translate-text').html(rst);
        },
        replaceCustomTextEvent(e,index,item){
            $(e.target).parents('.target-doc').find(".doc-input .doc").html(item.translationText);
            this.initTargetTranslationText(e,index,item);
            this.autoSaveSentences(index,'non-confirm',true);
        },
        initTargetAutocompleteEvent(){
            let self = this;
            
            $(document).on('keydown',function(e){
                let isShow = $('.target-autocomplete-input').css('display') === 'block';
                let keyCode=e.keyCode;
                switch(keyCode){
                    case 40: //下键  key = "ArrowDown"
                        if(isShow){
                            console.log('document ArrowDown');
                            $('.back-btn').focus();
                            let index = self.targetAutocompleteActiveIndex + 1;
                            if(index > (self.autocompleteList.length - 1)){
                                index = 0;
                            }
                            self.targetAutocompleteActiveIndex = index;
                        }else{
                            let obj = $('.editer-main .item.active').next().find('.target-doc .doc')[0];
                            tools.setCursorAtTheEnd(obj);
                        }
                        break; 
                    case 38: //上键  key = "ArrowUp"
                        let _index = $('.editer-main .item.active').data("docindex")*1;
                        if(self.pagination.pageNumber > 1 & _index === 0){
                            //向上翻页
                            self.$emit('prevPage');
                        }else{
                            if(isShow){
                                console.log('document ArrowUp');
                                $('.back-btn').focus();
                                let index = self.targetAutocompleteActiveIndex - 1;
                                if(index < 0){
                                    index = self.autocompleteList.length - 1;
                                }
                                self.targetAutocompleteActiveIndex = index;
                            }else{
                                let obj = $('.editer-main .item.active').prev().find('.target-doc .doc')[0];
                                tools.setCursorAtTheEnd(obj);
                            }
                        }
                        
                        break;
                    case 13: //回车键 key = "Enter"
                        // console.log('document enter');
                        if(isShow){
                            // console.log(self.autocompleteList[self.targetAutocompleteActiveIndex]);
                            let index = $('.editer-main .item.active').data("docindex");
                            let text = $('.editer-main .item.active .target-doc .doc').text() + self.autocompleteList[self.targetAutocompleteActiveIndex];
                            $('.editer-main .item.active .target-doc .doc').html(text);
                            $('.target-autocomplete-input').css({'display':'none'});
                            self.targetAutocompleteActiveIndex = 0;
                            self.autocompleteList=[];
                            let obj = $('.editer-main .item.active .target-doc .doc')[0];
                            tools.setCursorAtTheEnd(obj);
                            self.autoSaveSentences(index,'non-confirm',false);
                        }
                        break;
                }
            });
        },
    },
    mounted(){
        // this.initTargetAutocompleteEvent();
    },
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/variables.scss';
.basic-editer-vertical-component{
    // display: flex;
    // flex-direction: column;
    // height: 100%;
    min-height: 0;
    overflow-y: auto;
    > .title-content{
        display: flex;
        color: #00000066;
        font-size: 14px;
        span{
            margin: 0 5px;
            color: #222222;
        }
        i{
            color: $primary-color;
            font-size: 6px;
        }
        > .left{
            display: flex;
            flex: 1;
            > div{
                flex: 1;
                > .title-1{
                    margin-left: 50px;
                }
                > .title-2{
                    margin-left: 10px;
                }
            }
            
        }
        > .right{
            width: 450px;
            .title-3{
                margin-left: 20px;
                i{
                    margin-left: 5px;
                }
            }
        }
    }
    > .editer-main{
        display: flex;
        flex: 1;
        flex-direction: column;
        // margin-right:20px;
        &.edit-font-14{
             > .item > div{
                &.original-doc{
                    > .doc{
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
                &.target-doc{
                    > .doc-input{
                        > .doc{
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
        &.edit-font-15{
             > .item > div{
                &.original-doc{
                    > .doc{
                        font-size: 15px;
                        line-height: 22px;
                    }
                }
                &.target-doc{
                    > .doc-input{
                        > .doc{
                            font-size: 15px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }
        &.edit-font-16{
             > .item > div{
                &.original-doc{
                    > .doc{
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                &.target-doc{
                    > .doc-input{
                        > .doc{
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
        &.edit-font-17{
             > .item > div{
                &.original-doc{
                    > .doc{
                        font-size: 17px;
                        line-height: 26px;
                    }
                }
                &.target-doc{
                    > .doc-input{
                        > .doc{
                            font-size: 17px;
                            line-height: 26px;
                        }
                    }
                }
            }
        }
        &.edit-font-18{
             > .item > div{
                &.original-doc{
                    > .doc{
                        font-size: 18px;
                        line-height: 28px;
                    }
                }
                &.target-doc{
                    > .doc-input{
                        > .doc{
                            font-size: 18px;
                            line-height: 28px;
                        }
                    }
                }
            }
        }
        // margin-top: 20px;
            
        > .item{
            display: flex;
            > div{
                flex: 1;
                &.original-doc{
                    display: flex;
                    // background-color: #FAFAFA;
                    
                    > .tools{
                        width: 40px;
                        display: flex;
                        flex-direction: column;
                        color: #00000080;
                        &:last-child{
                            box-sizing: border-box;
                            border-bottom: 1px solid #0000000D;
                        }
                        > .line-number{
                            display: flex;
                            flex: 1;
                            align-items: center;
                            justify-content: center;
                            font-size: 14px;
                        }
                        > .merge-icon,> .split-icon{
                            display: none;
                            text-align: center;
                            cursor: pointer;
                            > i{
                                font-size: 20px;
                            }
                        }
                        > .split-icon{
                            margin-top: 5px;
                            flex: 1;
                        }
                    }
                    > .doc{
                        flex: 1;
                        height: 100%;
                        box-sizing: border-box;
                        border-bottom: 1px solid #0000000D;
                        padding: 18px 10px;
                        line-height: 20px;
                        outline: none;
                        font-size: 14px;
                        letter-spacing: 1px;
                        color: #00000080;
                        word-wrap:break-word;
                        // word-break:break-all;
                    }
                    
                }
                &.target-doc{
                    display: flex;
                    box-sizing: border-box;
                    border-left: 4px solid #DCDCDD;
                    border-bottom: 1px solid #0000000D;
                    
                    flex-direction:column;
                    > .doc-input{
                        
                        box-sizing: border-box;
                        position: relative;
                        > .doc{
                            flex: 1;
                            box-sizing: border-box;
                            padding: 18px 64px 18px 10px ;
                            line-height: 20px;
                            outline: none;
                            font-size: 14px;
                            color: #000000;
                            letter-spacing: 1px;
                            word-wrap:break-word;
                            // word-break:break-all;
                            &:focus{
                                // box-sizing: border-box;
                                // border: 1px solid #7DD6B3;
                                border-left: none;
                                
                            }
                            &[contenteditable]:empty:before{
                                content: attr(placeholder);
                                color: #CCCCCC;
                            }
                            &[contenteditable]:focus{
                                content:" ";
                            }
                        }
                        > .doc:focus + .tools{
                            display: flex;
                        }
                        > .tools{
                            position: absolute;
                            width: 44px;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            display: none;
                            flex-direction: column;
                            > .save-btn{
                                flex: 1;
                                display: flex;
                                align-items: center;
                                > .btn{
                                    width: 100%;
                                    height: 24px;
                                    line-height: 24px;
                                    text-align: center;
                                    cursor: pointer;
                                    color: #ffffff;
                                    font-size: 12px;
                                    background-color: #7DD6B3;
                                    border-radius: 20px 0 0 20px;
                                }
                            }
                            > .doc-word-num{
                                margin-bottom: 10px;
                                text-align: center;
                                font-size: 12px
                            }
                        }
                    }
                    > .doc-translate{
                        > .translate-text{
                            min-height: 20px;
                            cursor: pointer;
                            > .match{
                                color: #6666664D;
                            }
                        }
                        > .translate-engine{
                            position: absolute;
                            right: 10px;
                            top: 0px;
                            bottom: 0;
                            display: flex;
                            align-items: center;
                        }
                        &::after{
                            content: "";
                            border-top:1px dashed rgba(0, 0, 0, 0.05);
                            position: absolute;
                            top: 0;
                            left: 10px;
                            right: 10px;
                        }
                        position: relative;
                        padding: 2px 64px 2px 10px ;
                        line-height: 20px;
                        font-size:12px;
                        box-sizing: border-box;
                        color: #333333;
                        display: none;
                    }
                }
            }
            &.saved{
                .target-doc{
                    border-left-color: #7DD6B3;
                }
            }
            &.active{
                .original-doc{
                    .doc{
                        background-color: #E6F9F1;
                    }
                    .tools{
                        &:last-child{
                            background-color: #E6F9F1;
                        }
                    }
                }
                .target-doc{
                    background-color: #E6F9F1;
                    border-bottom: 1px solid #7DD6B3;
                    // border-left: none;
                    > .doc-input{
                        > .doc{
                            border: 1px solid #7DD6B3;
                            border-left: none;
                            border-bottom: none;
                        }
                        > .tools{
                            display: flex;
                        }
                    }
                    > .doc-translate{
                        display: block;
                        border-right: 1px solid #7DD6B3;
                    }
                }
            }
            &:hover,&:active,&:focus{
                .original-doc{
                    .doc{
                        background-color: #E6F9F1;
                    }
                    .tools{
                        &:last-child{
                            background-color: #E6F9F1;
                        }
                    }
                }
                .target-doc{
                    background-color: #E6F9F1;
                }
            }
        } 
    }
    > .pagination-content{
        padding: 20px 0;
        text-align: center;
    }
}
.target-autocomplete-input{
    max-height: 400px;
    overflow-y: auto;
    left: 0;
    position: absolute;
    display: none;
    background-color: #ffffff;
    border: 1px solid #E4E7ED;
    box-sizing: border-box;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 10px 0;
    > .item {
        min-width: 315px;
        max-width: 400px;
        // height: 10px;
        padding: 6px 10px;
        font-size: 14px;
        line-height: 20px;
        word-break: break-all;
        
        color: #000000;
        &.active{
            background-color: #E9F8F2;
        }
    }
}
</style>
