<template>
<el-dialog :visible.sync="show" :title="$t('workbench.component_ReferenceEngine_dialog_title')" @close="onClose" destroy-on-close class="none-padding-body" width="780px" top="20px">
    <!-- <div class="alert-warn-block text-center">最多支持5个常用引擎</div> -->
    <div class="engine-wrapper">
        <template v-for="domain in engineList">
            <div class="domian-name" :key="domain.key">{{domain.name}}</div>
            <div class="engine-list" :key="`engine-${domain.key}`">
                <template v-for="engine in domain.engines">
                    <div class="item" :class="{'active': selectedEngine && engine.key === selectedEngine.key}" :key="engine.key" @click="selectEngineEvent(engine)">
                        <svg-icon :name="`ie-${engine.icon}`" className="fonts-18"></svg-icon>
                        <span class="margin-l-5 fonts-12">{{ engine.name }}</span>
                    </div>
                </template>
            </div>
        </template>
    </div>
    <div slot="footer" style="text-align: center;">
        <el-button plain round size="small" @click="show = false">{{$t('workbench.component_ReferenceEngine_dialog_btn_1')}}</el-button>
        <el-button round type="primary" size="small" :loading="loading" @click="submitBtnEvent">{{$t('workbench.component_ReferenceEngine_dialog_btn_2')}}</el-button>
    </div>
</el-dialog>
</template>
<script>
import { projectApi } from '@/utils/api';
export default {
    data() {
        return {
            show: false,
            loading: false,
            engineList: [],
            selectedEngine: null,
            doc: null,
        }
    },
    methods: {
        setShow(param) {
            this.show = true;
            this.doc = param;
            this.getEngineList();
        },
        getEngineList() {
            let url = projectApi.queryReferenceEngineList;
            this.$ajax.post(url, {
                domain: this.doc.engineInfo.domain,
				engine: this.doc.engineInfo.key.split("@")[0],
				sourceLang: this.doc.sourceLang,
				targetLang: this.doc.targetLang,
                modelId: this.doc.engineInfo.modelId ? this.doc.engineInfo.modelId : undefined,
            }).then(res => {
                if(res.status === 200){
                    this.engineList = res.data;
                }
            })
        },
        onClose() {
            this.engineList = [];
            this.selectedEngine = null;
        },
        selectEngineEvent(engine) {
            this.selectedEngine = engine;
        },
        submitBtnEvent() {
            
        },
    }
}
</script>
<style lang="scss" scoped>
.engine-wrapper {
    padding: 20px 60px;
    .domian-name {
        font-size: 12px;
        color: #999999;
    }
    .engine-list {
        margin-top: 10px;
        margin-bottom: 20px;
        display: grid;
        gap: 10px;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        .item {
            cursor: pointer;
            box-sizing: border-box;
            border: 1px solid #f8f8f8;
            border-radius: 50px;
            height: 36px;
            background-color: #f8f8f8;
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.active {
                border-color: #000;
            }
        }
    }
}
</style>
