var render = function render(){var _vm=this,_c=_vm._self._c;return _c('table',{staticClass:"preview-table"},[_c('tbody',_vm._l((_vm.data.maxRowIdx+1),function(row){return _c('tr',{key:`row-${_vm.tools.getRandomId(6)}-${row}`,attrs:{"data-key":row}},_vm._l((_vm.data.maxColIdx+1),function(col){return _c('td',{key:`col-${_vm.tools.getRandomId(6)}-${col}`},[(_vm.data.table[row-1])?[(_vm.data.table[row-1][col-1])?[_vm._l((_vm.data.table[row-1][col-1]),function(paragraph,pIndex){return [(paragraph.type === 'paragraph')?[(['all','source'].indexOf(_vm.active) > -1)?_c('p',{key:`paragraph-source-${_vm.tools.getRandomId(6)}-${pIndex}`,style:(paragraph.style||{}),attrs:{"lang":_vm.doc.sourceLang}},[_vm._l((paragraph.sentences),function(sent,sIndex){return [_c('span',{key:`sent-source-${_vm.tools.getRandomId(6)}-${sIndex}`,class:[_vm.sentId === sent.sentId?'active':''],style:(sent.style?{
                                                ...(sent.style||{}),
                                                fontSize:(sent.style.fontSize*1<14?14:sent.style.fontSize)+'px',
                                                lineHeight:(parseFloat(sent.style.fontSize*1<14?14:sent.style.fontSize)+_vm.lineHeightAddition)+'px',
                                                backgroundColor:(sent.status === 'confirm'?'#CDF7E6':'')
                                            }:{}),attrs:{"data-sentid":sent.sentId||''},domProps:{"innerHTML":_vm._s(sent.source)}})]})],2):_vm._e(),(['all','target'].indexOf(_vm.active) > -1)?_c('p',{key:`paragraph-target-${_vm.tools.getRandomId(6)}-${pIndex}`,style:(paragraph.style?{...paragraph.style, textAlign:(_vm.doc.targetLang==='ar' && paragraph.style.textAlign==='left')?'inherit':paragraph.style.textAlign}:{}),attrs:{"lang":_vm.doc.targetLang}},[_vm._l((paragraph.sentences),function(sent,sIndex){return [_c('span',{key:`sent-target-${_vm.tools.getRandomId(6)}-${sIndex}`,class:[_vm.sentId === sent.sentId?'active':''],style:(sent.style?{
                                                ...(sent.style||{}),
                                                fontSize:(sent.style.fontSize*1<14?14:sent.style.fontSize)+'px',
                                                lineHeight:(parseFloat(sent.style.fontSize*1<14?14:sent.style.fontSize)+_vm.lineHeightAddition)+'px',
                                                backgroundColor:(sent.status === 'confirm'?'#CDF7E6':'')
                                            }:{}),attrs:{"data-sentid":sent.sentId||''},domProps:{"innerHTML":_vm._s(sent.target)}})]})],2):_vm._e()]:(paragraph.type === 'image')?[_c('p',{key:`image-${_vm.tools.getRandomId(6)}-${pIndex}`,staticClass:"image"},[_c('img',{attrs:{"src":_vm.item.url ? _vm.item.url : ('data:image/jpg;base64,' + _vm.item.encode),"alt":"image"}})])]:(paragraph.type === 'table')?[_c('NestedTable',{key:`child-table-${_vm.tools.getRandomId(6)}-${pIndex}`,attrs:{"data":paragraph,"active":_vm.active,"doc":_vm.doc,"sentId":_vm.sentId}})]:_vm._e()]})]:_vm._e()]:_vm._e()],2)}),0)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }