<template>
    <div class="qa-right-components">
        <div class="qa-panel">
            <div class="panel-title">
                <div class="title">
                    <svg-icon name="ib-qa"></svg-icon>
                    <div>{{$t('workbench.component_QARight_title')}}</div>
                </div>
                <div class="close" @click="closeQaPanel">
                    <el-tooltip :content="$t('workbench.component_QARight_tips_1')" placement="top">
                        <svg-icon name="ib-close"></svg-icon>
                    </el-tooltip>
                </div>
            </div>
            <div class="type-content">
                <div class="type-list">
                    <div :class="['item',filterType === 'ALL'?'active':'']" @click="initQaList">{{$t('workbench.component_QARight_tabs_item_1')}}</div>
                    <div :class="['item',filterType === 'IGNORE'?'active':'']" @click="initIgnoreList">{{$t('workbench.component_QARight_tabs_item_2')}}</div>
                </div>
                <div class="download-btn" >
                    <template>
                        <el-tooltip :content="$t('workbench.component_QARight_tips_2')" placement="left">
                            <i class="el-icon-setting margin-r-10" @click="$emit('action','qaSetting')"></i>
                        </el-tooltip>
                    </template>
                    <template v-if="data.length">
                        <el-tooltip :content="$t('workbench.component_QARight_tips_3')" placement="left">
                            <span @click="downloadQAResult">
                                <svg-icon name="ib-download"></svg-icon>
                            </span>
                        </el-tooltip>
                    </template>
                </div>
            </div>
            <div class="qa-content">
                <template v-if="filterType === 'ALL'">
                    <div class="qa-list" v-if="data.length">
                        <template  v-for="(item, index) in data">
                            <div class="item warn" :class="{'active': issueId === item.sentenceId}" 
                                :data-sid="item.sentenceId" :data-id="item.problemId" :key="index" 
                                @click="e=>qaItemClickEvent(e, item)">
                                <div class="status-content">
                                    <div class="status warn"></div>
                                </div>
                                <div class="info-content">
                                    <div class="default-info">
                                        <div class="name"><span v-html="item.problemText"></span></div>
                                        <!-- <div class="type">{{item.suggestion}}</div> -->
                                    </div>
                                    <template v-if="item.type === 'UNIFORMITY'">
                                        <div class="" v-if="item.examples && item.examples.length">
                                            <template v-for="(item, eIndex) in item.examples">
                                                <p :key="eIndex">{{ item.text }}</p>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                                <div class="ignore-content">
                                    <el-tooltip :content="$t('workbench.component_QARight_tips_4')" placement="top">
                                        <span @click.stop="ignoreQABtnEvent(item)">
                                            <svg-icon name="ib-close"></svg-icon>
                                        </span>
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="none-block" v-else>{{$t('workbench.component_QARight_empty_message_1')}}</div>
                </template>
                <template v-else-if="filterType === 'IGNORE'">
                    <div class="qa-list" v-if="ignoreList.length">
                        <template  v-for="(item, index) in ignoreList">
                            <div class="item warn" :class="{'active': issueId === item.sentenceId}" 
                                :data-sid="item.sentenceId" :data-id="item.problemId" :key="index" 
                                @click="e=>qaItemClickEvent(e, item, 'ignore')">
                                <div class="status-content">
                                    <div class="status warn"></div>
                                </div>
                                <div class="info-content">
                                    <div class="default-info">
                                        <div class="name"><span v-html="item.problemText"></span></div>
                                        <!-- <div class="type"></div> -->
                                    </div>
                                    <div v-if="item.operatorName" class="operator-info margin-t-2">{{$t('workbench.component_QARight_tips_5')}}{{item.operatorName}}</div>
                                    <template v-if="item.type === 'UNIFORMITY'">
                                        <div class="" v-if="item.examples && item.examples.length">
                                            <template v-for="(item, eIndex) in item.examples">
                                                <p :key="eIndex">{{ item.text }}</p>
                                            </template>
                                        </div>
                                    </template>
                                </div>
                                <div class="ignore-content">
                                    <el-tooltip :content="$t('workbench.component_QARight_tips_6')" placement="top">
                                        <span @click.stop="cancelIgnoreQABtnEvent(item)">
                                            <svg-icon name="ib-close"></svg-icon>
                                        </span>
                                    </el-tooltip>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="none-block" v-else>{{$t('workbench.component_QARight_empty_message_2')}}</div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import { debounce } from 'lodash';
import $ from 'jquery';
import { docApi, qaApi, commonApi } from '@/utils/api';
export default {
    name:"QARight",
    props:{
        doc: {
            type: Object,
            default: null,
        },
        data:{
            type: Array,
            default: [],
        },
        issueId:{
            type:String,
            default:''
        },
        sentPermission: {
            type: Boolean,
            default: false,
        },
    },
    data(){
        return {
            filterType:'ALL',
            ignoreList: [],
        }
    },
    methods:{
        closeQaPanel(){
            this.$emit('close');
        },
        qaItemClickEvent: debounce(function(e, item, type){
            this.$emit('locate', item, type);
        }),
        initQaList(){
            this.filterType = 'ALL';
            this.$emit('init');
        },
        ignoreQABtnEvent(item){
            this.$emit('ignore',item);
        },
        cancelIgnoreQABtnEvent(item){
            let url = qaApi.cancelIgnoreQAProblem;
            let postData = {
                documentId: this.$route.query.id,
                ignoreRecordIdList: [item.recordId],
            };
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    // this.$message.success('取消忽略成功');
                    this.initIgnoreList();
                }
            })
        },
        initIgnoreList() {
            this.filterType = 'IGNORE';
            let url = `${qaApi.ignoreQAList}?documentId=${this.$route.query.id}`;
            this.$ajax.get(url).then(res=>{
                if(res.status === 200){
                    this.ignoreList = res.data.map(item => {
                        return {
                            ...item,
                            problemText: 
                                item.template
                                    .replaceAll('{{abnormalText}}', `<span style="color: orange;">${item.abnormalText}</span>`)
                                        .replaceAll('{{suggestedText}}', `<span style="color: #05c212;">${item.suggestedText}</span>`),
                        }
                    });
                }
            });
        },
        downloadQAResult(){
            let url = commonApi.batchCreateDownloadTask;
            this.$ajax.post(url, {
                title: this.$t('workbench.component_QARight_method_downloadQAResult_title', {name: this.doc.originalFilename}),
                metadataIdList: [this.doc.docId],
                metadataType:'EDITOR_QA_REPORT',
            }).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(()=>{
                
            })
        },
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/variables.scss';
.qa-right-components{
    height: 100%;
    width: 380px;
    
    overflow-y: hidden;
    // flex: 1;
    .qa-panel{
        display: flex;
        flex-direction: column;
        height: 100%;
        .panel-title{
            margin: 0 20px;
            
        }
        .type-content{
            display: flex;
            justify-content: space-between;
            .setting-btn{
                display: flex;
                align-items: center;
            }
            .download-btn{
                margin-right: 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }
        .type-list{
            height: 37px;
            display: flex;
            margin: 10px 20px;
            align-items: center;
            > .item{
                cursor: pointer;
                margin-right: 25px;
                font-size: 13.5px;
                line-height: 17px;
                color: #000000;
                border-bottom: 1px solid #ffffff;
                box-sizing: border-box;
                padding-bottom: 5px;
                &.active{
                    border-color: #000000;
                    // color: #7DD6B3;
                    font-weight: 600;

                }
                > .size{
                    // width:21px;
                    font-size: 12px;
                    color: #FFFFFF;
                    padding: 0 4px;
                    margin-right: 5px;
                    background-color: $info-color;
                    border-radius: 20px;
                    font-weight: 400;
                    -webkit-transform-origin-x: 0;
                    -webkit-transform: scale(0.90);
                    &.error{
                        background-color: $error-color;
                    }
                    &.warn{
                        background-color: $warn-color;
                    }
                }
            }
        }
        .qa-content{
            flex: 1;
            position: relative;
            .qa-list{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                overflow: auto;
                padding: 0 20px;
                > .item{
                    background-color:#FAFAFA;
                    border-radius: 8px; 
                    border: 1px solid #ffffff;
                    box-sizing: border-box;
                    margin-bottom: 8px;
                    padding: 10px 6px;
                    display: flex;
                    // cursor: pointer;
                    &.error{
                        &:hover, &.active{
                            border-color: $error-color;
                        }
                        > .status-content{
                            > .status{
                                background-color: $error-color;
                            }
                        }
                    }
                    &.warn{
                        &:hover, &.active{
                            border-color: $warn-color;
                        }
                        > .status-content{
                            > .status{
                                background-color: $warn-color;
                            }
                        }
                    }
                    &.info{
                        &:hover, &.active{
                            border-color: $info-color;
                        }
                        > .status-content{
                            > .status{
                                background-color: $info-color;
                            }
                        }
                    }
                    > .status-content{
                        width: 26px;
                        height: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        > .status{
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            background-color: $info-color;
                        }
                    }
                    > .info-content{
                        flex: 1;
                        font-size: 12px;
                        min-width: 0;
                        > .default-info{
                            display: flex;
                            // height: 20px;
                            align-items: center;
                            cursor: pointer;
                            > .name{
                                // margin-right: 10px;
                                color: #000000;
                                line-height: 1.5;
                                // min-width: 0;
                                flex: 1;
                                // overflow: hidden;
                                // text-overflow: ellipsis;
                                // white-space: nowrap;
                            }
                            > .type{
                                color: #00000066;
                            }
                        }
                        .operator-info{
                            color: #00000066;
                        }
                        > .segment-num-info{
                            height: 20px;
                            display: none; //flex
                            align-items: center;
                            color: #00000066;
                            > .name{
                                margin-right: 10px;
                                min-width: 0;
                                flex: 1;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            > .type{
                                color: #00000066;
                            }
                        }
                        > .more-info{
                            display: none;
                            margin-top: 10px;
                            > .modification-info{
                                display: flex; 
                                color: #000000;
                                font-size: 14px;
                                align-items: center;
                                flex-wrap:wrap; 
                                > .problem{
                                    text-decoration: line-through;
                                    text-decoration-color: $error-color;
                                }
                                > .arrow{
                                    font-size: 16px;
                                    margin: 0 8px;
                                }
                                .reference{
                                    padding: 4px 10px;
                                    background-color: #7DD6B3;
                                    border-radius: 20px;
                                    cursor: pointer;
                                }
                            }
                            > .problem-desc{
                                margin-top: 10px;
                                font-size: 12px;
                                line-height: 18px;
                                word-wrap: break-word;
                            }
                        }
                        p {
                            line-height: 1.5;
                        }
                    }
                    > .ignore-content{
                        width: 32px;
                        height: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        svg{
                            font-size: 12px;
                            color: #00000066;
                            &:hover{
                                color: #000;
                            }
                        }
                    }
                }
            }
            .none-block{
                margin-top: 20px;
                text-align: center;
            }
        }
    }
}
</style>
